<template>
  <ion-page>
    <ion-content class="page" :fullscreen="true">
      <div v-if="!isLogin">
        <div class="login-title">
          您还未登录，登录后才可以查看
        </div>
        <div>
          <a class="login-button" href="/login">登录</a>
        </div>
      </div>
      <div v-if="isLogin">
        <div class="login-title">
          您好！{{ sellerName }}
        </div>
        <div>
          <a class="login-button" @click="logout">退出登录</a>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {IonContent, IonPage} from '@ionic/vue';
import {getUserInfo} from "@/api/user";
import {removeToken} from "../utils/auth";

export default {
  name: 'Tab3',
  components: {IonContent, IonPage},
  data() {
    return {
      isLogin: true,
      sellerName: "",
      qrcode: null,
      vipLevel: 0,
      saveMoneyAmount: 0,
      point: 0,
      nickName: ""
    }
  },
  created() {
    this.onShow()
  },
  methods: {
    /**
     * 生命周期函数--监听页面加载
     */
    onShow: function () {
      this.fetchData();
    },
    fetchData: function () {
      getUserInfo().then(res => {
        console.log(res.data);
        this.sellerName = res.data;
      }).catch(res => {
        console.log(res.data);
        if (res.data.code === 401) {
          this.isLogin = false;
        } else if (res.data.code === 200) {
          this.isLogin = true;
        }
      });
    },
    logout: function () {
      removeToken();
      this.isLogin = false;
    }
  }
}
</script>
<style lang="css">
.page {
  --ion-background-color: #f6f6f6;
  background: #f6f6f6;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

ion-item {
  --ion-background-color: white;
  --border-style: none;
  --background-activated: none;
}

.login-title {
  font-size: 20px;
  display: block;
  width: 300px;
  height: 50px;
  margin: 50px auto;
}

.login-button {
  display: block;
  width: 300px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  margin: 0 auto;
  background-color: #d81e06;
  color: white;
  font-weight: bold;
  text-decoration-line: none;
}

.user-info {
  display: flex;
  line-height: 100%;
  width: 375px;
  background-color: white;
  border-width: 0;
  flex-direction: column;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
}

.user-info-head {
  display: inline-flex;
  line-height: 100%;
  width: 375px;
  background-color: white;
  border-radius: 10px;
  border-width: 0;
  flex-direction: row;
}

.user-info-avatar {
  width: 50px;
  height: 50px;
  margin: 10px;
}

.user-info-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.user-info-nickname {
  height: 50px;
  margin: 10px;
  line-height: 50px;
}

.user-info-rank {
  display: inline-flex;
  line-height: 40px;
  width: 350px;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
  margin: 10px;
  background-size: 25%;
  background-color: white;
  background-position: 5px;
  background-repeat: no-repeat;
  border-radius: 10px;
  border-width: 1px;
  border-color: #f3f3f3;
  border-style: solid;
  box-shadow: 5px 5px 5px #eeeeee;
}

.user-info-vip {
  display: inline-flex;
  flex-direction: row;
  margin-left: 10px;
}

.user-info-vip-level {
  height: 40px;
  width: 50px;
  line-height: 40px;
  color: #d81e06;
  font-size: 25px;
  font-weight: bolder;
}

.user-info-point {
  margin-right: 10px;
  color: #d81e06;
}

.user-info-list {
  display: flex;
  line-height: 100%;
  width: 375px;
  background-color: white;
  border-width: 0;
  flex-direction: column;
  margin: 0 auto;
}

.user-info-item {
  display: inline-flex;
  line-height: 100%;
  width: 355px !important;
  font-weight: normal;
  border-bottom-color: #e6e6e6;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  flex-direction: row;
  padding: 10px 0;
  margin: 0 10px;
}

.user-info-item-last {
  border-bottom-width: 0 !important;
}

.user-info-item-first {
  display: inline-flex;
  line-height: 100%;
  width: 355px;
  background-color: white;
  flex-direction: row;
}

.user-info-item-icon {
  width: 20px;
  height: 20px;
  margin: 5px;
}

.user-info-item-title {
  line-height: 25px;
  margin-left: 5px;
  margin-top: 2px;
}

.user-info-item-arrow {
  width: 20px;
  height: 20px;
  margin: 5px;
}
</style>
